<div class="footer">
  <div class="information">
    <div class="about">
      <h3>About</h3>
      <p><span class="title">Cold Turkey Entertainment</span> was founded in 2019 and aims to bring laughter, tears, and occasionally deep philosophical thought to the people through all mediums.</p>
    </div>
    <div class="contact">
      <h3>Contact</h3>
      <a href="mailto:andrew@coldturkeyentertainment.co.uk" target="_blank"><fa-icon [icon]="openEnvelope"></fa-icon> andrew@coldturkeyentertainment.co.uk </a>
    </div>
  </div>
  <div class="accreditation">
    <!--<p><span class="title">Cold Turkey Entertainment</span> logo designed by <i>Ana Olarou</i></p>-->
  </div>
</div>
