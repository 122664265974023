<div class="page-header"><h1>News</h1></div>
<div class="news-section">
  <div>
    <div class="news-panels">
      <div *ngFor="let items of news; last as final" [ngClass]="{'final': final, 'not-final': !final}">
        <div class="news-panel">
          <div class="news-item">
            <p>{{items.date}}</p>
            <h3>{{items.title}}</h3>
            <p class="content">{{items.text}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="page-header"><h1>Current Productions</h1></div>
<div class="main-section">
  <div>
    <div class="production-panels">
      <div *ngFor="let production of productions; last as final" [ngClass]="{'final': final, 'not-final': !final}">
        <div class="production-panel" *ngIf="production.display === true">
          <div class="poster-section">
            <img src="{{production.mainPageInformation.poster}}">
            <ng-container *ngIf="production.mainPageInformation.posterCredit">
              <span>{{production.mainPageInformation.posterCredit}}</span>
              <a class="poster-credit-link" href="https://www.instagram.com/{{production.mainPageInformation.creditLink}}" target="_blank"><fa-icon [icon]="faInstagram"></fa-icon></a>
            </ng-container>
          </div>
          <div class="panel-info">
            <h4>{{production.type}}</h4>
            <span class="breaker">---------</span>
            <h2>{{production.title}}</h2>
            <div>
              by <br />{{production.author}}
            </div>
            <div *ngIf="production.mainPageInformation" class="dates">
              {{production.mainPageInformation.releaseDates}}
            </div>
            <div *ngIf="production.mainPageInformation.ticketPrice">Tickets: {{production.mainPageInformation.ticketPrice}}</div>
            <div class="information">
              <div>
                {{production.mainPageInformation.synopsis}}
              </div>
              <br />
              <div>
                <span class="title">{{production.title}} </span>{{production.description}}
              </div>
            </div>
            <a routerLink="/production/{{production.route}}"><button [disabled]="production.infoCurrentlyAvailable==false" class="production-button">More Info</button></a>
            <a *ngIf="production.type === 'theatre'; else elseBlock" href="https://{{production.mainPageInformation.purchaseLink}}" target="_blank"><button class="production-button">Book Tickets</button></a>
            <ng-template #elseBlock><a href="https://{{production.mainPageInformation.purchaseLink}}" target="_blank"><button class="production-button">Purchase</button></a></ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
