import { Component, OnInit } from '@angular/core';
import { Productions } from '../productions-list';
import { NewsItems } from '../news-list';

import { faInstagram } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  productions = Productions;
  news = NewsItems;
  faInstagram = faInstagram;

  constructor() { }

  ngOnInit() {
    this.productions.sort((a, b) => a.id - b.id);
    window.scrollTo(0, 0);
  }

}
