<div *ngFor="let production of productions">
  <div *ngIf="production.route === title">
    <!--<div class="page-header"><h1>{{production.title}}</h1></div>-->
    <div class="main-section">
      <div class="banner"><img src="{{production.productionPageInformation.banner}}"></div>
      <!--Trailer Section-->
      <ng-container *ngIf="production.productionPageInformation.trailerLink !=null">
        <h3 class="trailer-title">Trailers</h3>
        <div class="trailer-block" *ngFor="let trailer of production.productionPageInformation.trailerLink">
          <iframe width="100%" height="100" scrolling="no" frameborder="no" allow="autoplay" [src]="'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/'+trailer+'&color=%237a0808&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=false' | trust"></iframe>
        </div>
      </ng-container>
      <!-- Mobile -->
      <div class="mobile-details">
        <ngb-accordion [closeOthers]="true">
          <!--Details-->
          <ngb-panel title="Details">
            <ng-template ngbPanelContent>
              <div>{{production.productionPageInformation.description.paragraphOne}}</div>
              <span class="breaker">---------</span>
              <br />
              <div *ngIf="production.productionPageInformation.description.paragraphTwo != null">{{production.productionPageInformation.description.paragraphTwo}}</div>
              <span *ngIf="production.productionPageInformation.description.paragraphTwo != null" class="breaker">---------</span>
              <br *ngIf="production.productionPageInformation.paragraphTwo != null" />
              <div><span class="title">{{production.title}} </span>{{production.description}}</div>
            </ng-template>
          </ngb-panel>
          <!--Cast List-->
          <ngb-panel title="Cast">
            <ng-template ngbPanelContent>
              <div *ngFor="let member of production.productionPageInformation.cast">
                <div><i>{{member.name}}</i> as <i>{{member.role}}</i></div>
                <span class="breaker">---------</span>
              </div>
            </ng-template>
          </ngb-panel>
          <!--Crew List-->
          <ngb-panel title="Crew" *ngIf="production.type === 'theatre'">
            <ng-template ngbPanelContent>
              <div *ngFor="let member of production.productionPageInformation.crew">
                <div><i>{{member.name}}</i> - {{member.role}}</div>
                <span class="breaker">---------</span>
              </div>
            </ng-template>
          </ngb-panel>
          <!--Ticket Booking-->
          <ngb-panel title="Book Tickets" *ngIf="production.type === 'theatre'">
            <ng-template ngbPanelContent>
              <div *ngFor="let venue of production.productionPageInformation.venues">
                <div>{{venue.date}} @ {{venue.location}} - Starts {{venue.time}}</div>
                <a href="https://{{venue.bookingLink}}" target="_blank"><button [disabled]="production.mainPageInformation.ticketsOnSale == false" class="production-button">Book Tickets</button></a>
                <br />
                <span class="breaker">---------</span>
              </div>
            </ng-template>
          </ngb-panel>
          <!--Venue Information-->
          <ngb-panel title="Venue Information" *ngIf="production.type === 'theatre'">
            <ng-template ngbPanelContent>
              <div *ngFor="let venueInformation of production.productionPageInformation.venueInformation">
                <div>
                  <h3>{{venueInformation.venue}}</h3>
                  <div class="directions">
                    <div class="map"><img src="{{venueInformation.map}}" /></div>
                    <div class="direction-info"><p>{{venueInformation.address}}</p><p>{{venueInformation.notes}}</p></div>
                  </div>
                </div>
                <br />
                <span class="breaker">---------</span>
              </div>
            </ng-template>
          </ngb-panel>
          <!--Episode Listing-->
          <ngb-panel title="Episodes" *ngIf="production.type === 'audio drama'">
            <ng-template ngbPanelContent>
              <ngb-accordion [closeOthers]="true">
                <ng-container *ngFor="let episode of production.productionPageInformation.episodes">
                  <ngb-panel title="• {{episode.title}}">
                    <ng-template ngbPanelContent>
                      <div class="quotes">“{{episode.quote}}”</div>
                      <span class="centre-breaker">---------</span>
                      <div class="mobile-description">{{episode.description}}</div>
                      <div class="releases">Release Date: {{episode.releaseDate}}</div>
                      <div *ngIf="episode.guests != null" class="guest-title">Guest Starring</div>
                      <div class="guests">&nbsp;&nbsp;<i *ngFor="let guest of episode.guests; last as isLast">{{guest.name}}<span *ngIf="!isLast">, </span></i></div>
                    </ng-template>
                  </ngb-panel>
                </ng-container>
              </ngb-accordion>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
      <!-- Desktop -->
      <div class="details">
        <div class="tabs">
          <ngb-tabset>
            <!--Details-->
            <ngb-tab title="Details">
              <ng-template ngbTabContent>
                <div>{{production.productionPageInformation.description.paragraphOne}}</div>
                <span class="breaker">---------</span>
                <br />
                <div *ngIf="production.productionPageInformation.description.paragraphTwo != null">{{production.productionPageInformation.description.paragraphTwo}}</div>
                <span *ngIf="production.productionPageInformation.description.paragraphTwo != null" class="breaker">---------</span>
                <br *ngIf="production.productionPageInformation.paragraphTwo != null" />
                <div><span class="title">{{production.title}} </span>{{production.description}}</div>
              </ng-template>
            </ngb-tab>
            <!--Cast List-->
            <ngb-tab title="Cast">
              <ng-template ngbTabContent>
                <div class="cast">
                  <div class="castmembers" *ngFor="let member of production.productionPageInformation.cast">
                    <div><i>{{member.name}}</i></div>
                    <div class="connector">as</div>
                    <div><i>{{member.role}}</i></div>
                    <span class="breaker">---------</span>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
            <!--Crew List-->
            <ngb-tab *ngIf="production.type === 'theatr'" title="Crew">
              <ng-template ngbTabContent>
                <div *ngFor="let member of production.productionPageInformation.crew">
                  <div><i>{{member.name}}</i> - {{member.role}}</div>
                  <span class="breaker">---------</span>
                </div>
              </ng-template>
            </ngb-tab>
            <!--Ticket Booking-->
            <ngb-tab *ngIf="production.type === 'theatre'" title="Book Tickets">
              <ng-template ngbTabContent>
                <div *ngFor="let venue of production.productionPageInformation.venues">
                  <div>{{venue.date}} @ <i>{{venue.location}}</i> - Starts {{venue.time}}</div>
                  <a href="https://{{venue.bookingLink}}" target="_blank"><button [disabled]="production.mainPageInformation.ticketsOnSale == false" class="production-button">Book Tickets</button></a>
                  <br />
                  <span class="breaker">---------</span>
                </div>
              </ng-template>
            </ngb-tab>
            <!--Venue Information-->
            <ngb-tab *ngIf="production.type === 'theatre'" title="Venue Information">
              <ng-template ngbTabContent>
                <div *ngFor="let venueInformation of production.productionPageInformation.venueInformation">
                  <div>
                    <h3>{{venueInformation.venue}}</h3>
                    <div class="directions">
                      <div class="direction-info"><p>{{venueInformation.address}}</p><p><strong>Note: </strong>{{venueInformation.notes}}</p></div>
                    </div>
                  </div>
                  <br />
                  <span class="breaker">---------</span>
                </div>
              </ng-template>
            </ngb-tab>
            <!--Episode Listing-->
            <ngb-tab *ngIf="production.type === 'audio drama'" title="Episodes">
              <ng-template ngbTabContent>
                <div *ngFor="let episode of production.productionPageInformation.episodes">
                  <div class="episode">{{episode.title}}</div>
                  <div class="releases">{{episode.releaseDate}}</div>
                  <div class="quotes">“{{episode.quote}}”</div>
                  <div>{{episode.description}}</div>
                  <div *ngIf="episode.guests != null" class="guest-title">Guest Starring</div>
                  <div class="guests">&nbsp;&nbsp;<i *ngFor="let guest of episode.guests; last as isLast">{{guest.name}}<span *ngIf="!isLast">, </span></i></div>
                  <a *ngIf="episode.free === false; else elseBlock" href="https://{{episode.purchaseLink}}" target="_blank"><button class="production-button">Purchase</button></a>
                  <ng-template #elseBlock>
                    <a href="https://{{episode.purchaseLink}}" target="_blank"><button class="production-button">Listen</button></a>
                  </ng-template>
                  <br />
                  <span class="breaker">---------</span>
                </div>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
</div>
