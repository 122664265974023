<div class="header">
  <div class="header-row">
    <div class="logo-section">
      <a class="logo" routerLink="/"><img src="./assets/images/logos/cold_turkey_logo_white_circle.png"></a>
    </div>
    <div class="social-media">
      <ul>
        <li><a href="https://www.facebook.com/ColdTurkeyEntertainment/" target="_blank"><fa-icon [icon]="faFacebook"></fa-icon></a></li>
        <!--<li><a href="https://twitter.com/ColdTurkeyEnt" target="_blank"><fa-icon [icon]="faTwitter"></fa-icon></a></li>-->
        <li><a href="https://www.instagram.com/coldturkeyentertainment/" target="_blank"><fa-icon [icon]="faInstagram"></fa-icon></a></li>
        <li><a href="https://soundcloud.com/user-350355302" target="_blank"><fa-icon [icon]="faSoundcloud"></fa-icon></a></li>
      </ul>
    </div>
  </div>
</div>
