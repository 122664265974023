import { Production } from './production';

export const Productions: Production[] = [
  {
    id: 1,
    type: "theatre",
    title: "A Double Bill of Cuthbert Masterstroke",
    route: "a-double-bill-of-cuthbert-masterstroke",
    author: "Andrew J Smith",
    description: "is a two episode detective comedy play (likened to such things as 'Airplane!' and 'The Goon Show') detailing the early adventures of private eye, Cuthbert Masterstroke, and his faithful young assistant, Diana St. Partridge.",
    display: true,
    infoCurrentlyAvailable: true,

    mainPageInformation: {
      poster: "./assets/images/posters/CMPlayRevisitedPoster.jpg",
      synopsis: "Cuthbert Masterstroke has aspired to be a private detective most of his life. But even more so in the past week. In his early weeks on the job, can he avoid certain death whilst investigating the certain death of others? Does the brick thrower of Brick Lane have other ideas? And who exactly are all of these dead people?",
      releaseDates: "26th-29th April 2023",
      ticketPrice: "£10",
      purchaseLink: "www.ticketsource.co.uk/cold-turkey-entertainment",
      ticketsOnSale: true
    },

    productionPageInformation: {
      banner: "./assets/images/banners/CMDoubleBill-Banner.png",
      description: {
        paragraphOne: "Cuthbert Masterstroke is just your average, ordinary detective. No wait, it’s much worse than that. Cuthbert Masterstroke is a bit of an idiot. In his first week as a private detective, he hasn’t had a lot to deal with. But just as he is about to get a shiny new door, everything begins to go wrong. People are dying, and bricks are involved. Could this have something to do with the brick thrower of Brick Lane? It is up to him (or the police) to find out in “The Many Faces of Cuthbert Masterstroke”.",
        paragraphTwo: "In “Cuthbert Masterstroke in ‘A Double Bill’”, Cuthbert must solve the murder of a man at the Upper Class Detective’s Annual Gathering. Or is it two men, both called Bill. When a new detective arrives on the scene and accuses his assistant, who can Cuthbert believe? The newcomer, his faithful companion, or the parrot? To complicate matters (as if they weren’t already complicated enough), nobody can quite figure out how the corpse even came to be there."
      },
      cast: [
        {
          name: "Andrew J Smith",
          role: "Cuthbert Masterstroke",
          headshot: null
        },
        {
          name: "Emily Browne",
          role: "Diana St. Partridge / Man",
          headshot: null
        },
        {
          name: "Mike Brook",
          role: "Detective Inspector Wye / Applicant",
          headshot: null
        },
        {
          name: "Vikki Goldsmith",
          role: "Postwoman / Old Man",
          headshot: null
        },
        {
          name: "Ceri Lawrence",
          role: "Abigail Tery / Maid",
          headshot: null
        },
        {
          name: "Dave Marsh",
          role: "Colonel / Man #2 / Policeman / Bill",
          headshot: null
        }
      ],
      crew: [
        {
          name: "Nick Prince",
          role: "Sound",
          headshot: null
        },
        {
          name: "Pete Humphreys",
          role: "Lighting",
          headshot: null
        },
        {
          name: "Sarah Pearce",
          role: "Stage Manager",
          headshot: null
        },
      ],
      venues: [
        {
          date: "26th April 2023",
          location: "The William Penney Theatre, Aldermaston Recreational Society, Aldermaston",
          time: "8pm",
          bookingLink: "www.ticketsource.co.uk/cold-turkey-entertainment"
        },
        {
          date: "27th April 2023",
          location: "The William Penney Theatre, Aldermaston Recreational Society, Aldermaston",
          time: "8pm",
          bookingLink: "www.ticketsource.co.uk/cold-turkey-entertainment"
        },
        {
          date: "28th April 2023",
          location: "The Oakwood Centre, Woodley",
          time: "8pm",
          bookingLink: "www.ticketsource.co.uk/cold-turkey-entertainment"
        },
        {
          date: "29th April 2023",
          location: "The Oakwood Centre, Woodley",
          time: "8pm",
          bookingLink: "www.ticketsource.co.uk/cold-turkey-entertainment"
        }
      ],
      venueInformation: [
        {
          venue: "The William Penney Theatre",
          address: "Tadley, Berks, RG7 4PR",
          notes: "There is a car park on site - parking is free. To get to the theatre from the car park, you must go through the Aldermaston Recreational Society building.",
          map: "./assets/images/maps/WilliamPenney.jpg"
        },
        {
          venue: "The Oakwood Centre",
          address: "Headley Road, Woodley, Berkshire, RG5 4JZ",
          notes: "There is a car park opposite the venue - parking is free after 6pm.",
          map: "./assets/images/maps/Oakwood.jpg"
        }
      ]
    }
  },
  {
    id: 2,
    type: "audio drama",
    title: "Cuthbert Masterstroke... Investigates?",
    route: "cuthbert-masterstroke-investigates",
    author: "Andrew J Smith",
    description: "is a series of comedy audio shorts documenting the ongoing mysteries Cuthbert Masterstroke has to face, no matter how much horse they may contain. If your ears could do with a laugh, then laughing at Cuthbert is easy.",
    display: true,

    mainPageInformation: {
      poster: "./assets/images/posters/Cuthbert-Masterstroke-Investigates-Cover.jpg",
      posterCredit: "Cover Art by Jon Hudson Art",
      creditLink: "JonHudsonArt",
      synopsis: "Who is sending death threats to the local cigar manufacturer? Why hasn’t that body been hidden very well? Who has stolen the fake money? Where did that horse come from? All these questions and a lot more you may or may not understand will be answered when Cuthbert Masterstroke is on the case. Let’s just hope he remembers to be.",
      releaseDates: "Out Now",
      purchaseLink: "coldturkeyentertainment.bandcamp.com/releases"
    },

    productionPageInformation: {
      banner: "./assets/images/banners/CMInvestigates-Banner.png",
      description: {
        paragraphOne: "Who is sending death threats to the local cigar manufacturer? Why hasn’t that body been hidden very well? Who has stolen the fake money? Where did that horse come from? All these questions and a lot more you may or may not understand will be answered when Cuthbert Masterstroke is on the case. Let’s just hope he remembers to be.",
        paragraphTwo: null
      },
      trailerLink: [
        "970275271",
        "970060918"
      ],
      cast: [
        {
          name: "Andrew J Smith",
          role: "Cuthbert Masterstroke"
        },
        {
          name: "Rachel Silk",
          role: "Diana St. Partridge"
        }
      ],
      episodes: [
        {
          title: "Prologue: A Sample Mystery",
          quote: "You're right, I can really feel myself waiting.",
          description: "Cuthbert has always longed for more hair. So when a salesman turns up claiming to have a way to restore his head to its former glory, it sounds too good to be true. Especially when that salesman drops down dead while demonstrating the tonic. Is this a simple case of mistaken formula, or is there something a lot less sinister going on...",
          free: true,
          guests: [
            {
              name: "Dave Marsh",
              role: "Me"
            }
          ],
          releaseDate: "Out Now",
          soundcloudLink: "https://soundcloud.com/user-350355302/cuthbert-masterstroke-investigates-sample",
          purchaseLink: "coldturkeyentertainment.bandcamp.com/releases"
        },
        {
          title: "I: Smoke & Kippers",
          quote: "I'll go downstairs and warm up my feet.",
          description: "Somebody has been sending death threats to the local cigar manufacturer. For some unknown reason he has come to Cuthbert for help. This seems to be entirely the wrong choice when he is shot in Cuthbert's office, and even more so when he asks Cuthbert for more help with his dying breath. Will the dynamic twosome solve the crime? You won't know unless you listen.",
          free: false,
          guests: [
            {
              name: "William Smith",
              role: "McTavish"
            }
          ],
          releaseDate: "Out Now",
          purchaseLink: "coldturkeyentertainment.bandcamp.com/releases"
        },
        {
          title: "II: The Return of the Affair of the Fairly Unfair Affair Returns",
          quote: "I was just going to open my door for the first time, expecting nobody to be there. But somebody had other ideas.",
          description: "Cuthbert has noticed a familiar smell, one that reminds him of his first case - The Affair of the Fairly Unfair Affair. It should then come as no surprise, although it does to him, to find that the woman who gave him the case has returned, with reports of blackmail.",
          free: false,
          guests: [
            {
              name: "Jenny Woolf",
              role: "Edith La Ma Pelle Crunchier"
            }
          ],
          releaseDate: "Out Now",
          purchaseLink: "coldturkeyentertainment.bandcamp.com/releases"
        },
        {
          title: "III: The Body No Longer in the Clock",
          quote: "Alright pigeon, if that is your real name. We have ways of making you talk.",
          description: "Back in the office, Diana is insistent that they investigate the body that was previously concealed in the clock. There is just one...two...many problems: firstly, Edith has disappeared. Secondly, somebody else has turned up. Thirdly, Cuthbert is clueless. All in all a normal day.",
          free: false,
          guests: [
            {
              name: "Jenny Woolf",
              role: "Edith La Ma Pelle Crunchier"
            }
          ],
          releaseDate: "Out Now",
          purchaseLink: "coldturkeyentertainment.bandcamp.com/releases"
        },
        {
          title: "IV: The Mysterious Case of the Horse, Or Possibly Something Else",
          quote: "The staff are not important enough to be oiled.",
          description: "Who would have thought that Cuthbert buying a horse would not be the most peculiar thing that happened on this particular day? Apparently the Heiress Madame Elizabeth Unknown of Unknown Hall, who has just shown up with a requirement for somebody to investigate strange noises for her. A trip to Unknown Hall seems necessary, or at least extremely advisable.",
          free: false,
          guests: [
            {
              name: "Emily Browne",
              role: "The Right Honourable Heiress Madame Elizabeth Unknown of Unknown Hall"
            },
            {
              name: "Dave Marsh",
              role: "The Butler"
            },
            {
              name: "Nicky Kirby",
              role: "Recurring Character"
            }
          ],
          releaseDate: "Out Now",
          purchaseLink: "coldturkeyentertainment.bandcamp.com/releases"
        },
        {
          title: "V: The Horse's Revenge",
          quote: "He gave me the excuse that he’s a horse and as such has no concept of private property, but personally I think he’s up to something.",
          description: "With events at Unknown Hall becoming more and more unknown with each passing minute, Cuthbert and Diana are thrust into a case that contains all three key ingredients of a successful mystery: Intrigue, action, and nonsense. Luckily Cuthbert is an expert with at least one of these.",
          free: false,
          guests: [
            {
              name: "Emily Browne",
              role: "The Right Honourable Heiress Madame Elizabeth Unknown of Unknown Hall"
            },
            {
              name: "Dave Marsh",
              role: "The Butler"
            },
            {
              name: "Nicky Kirby",
              role: "Recurring Character"
            }
          ],
          releaseDate: "Out Now",
          purchaseLink: "coldturkeyentertainment.bandcamp.com/releases"
        },
        {
          title: "VI: Meanwhile, Remembering the Beginning...",
          quote: "I’m afraid I can’t. Under page 5, subsection 3, paragraph D of the official police rules for confrontation with an armed suspect, it states - ‘Don’t go near pointy objects’.",
          description: "To have come this far... only to have completely forgotten to finish solving the case you started at the beginning - this sounds like something Cuthbert would do. But to add to the things that “Cuthbert would do”, he is suddenly on a train to Scotland wearing some very important trousers, and being chased by not particularly esteemed police investigator, Detective Inspector Wye.",
          free: false,
          guests: [
            {
              name: "Dave Marsh",
              role: "Detective Inspector Wye (amongst others)"
            },
            {
              name: "Nicky Kirby",
              role: "Recurring Character"
            },
            {
              name: "William Smith",
              role: "A Voice"
            }
          ],
          releaseDate: "Out Now",
          purchaseLink: "coldturkeyentertainment.bandcamp.com/releases"
        }
      ]
    }
  }
]
