import { Component, OnInit, ViewChild } from '@angular/core';

import { Productions } from '../productions-list';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-production',
  templateUrl: './production.component.html',
  styleUrls: ['./production.component.scss']
})
export class ProductionComponent implements OnInit {

  productions = Productions;
  title: any;

  constructor(private _Activatedroute:ActivatedRoute) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  ngDoCheck() {
    this.title=this._Activatedroute.snapshot.paramMap.get("route");
  }
}
